const handlers = new WeakMap()

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const vm = handlers.get(entry.target)
        if (vm) {
            if (entry.isIntersecting) {
                if (!vm.onScreen) {
                    vm.onScreen = true 
                    if (typeof vm.onScreenEnter==='function') vm.onScreenEnter(entry)
                }
            } else if (vm.onScreen) {
                vm.onScreen = false 
                if (typeof vm.onScreenLeave==='function') vm.onScreenLeave(entry)
            }
        }
    })
}) 


export const onScreenMixin = { 
    data: () => ({ onScreen: false }), 
    methods: { 
        createObserver() { 
            if (this.$el) {
                observer.observe(this.$el)
                handlers.set(this.$el, this)
            }
        }, 
        destroyObserver() { 
            if (this.$el) {
                observer.unobserve(this.$el) 
                handlers.delete(this.$el)
            }
        }
    }, 
    mounted() { 
        this.createObserver() 
    }, 
    beforeDestroy() { 
        this.destroyObserver() 
    } 
} 