<template>		
<div class='atBottom'>
	<slot />
</div>
</template>

<script>
import { onScreenMixin } from '@/components/common/mixins/onScreen'

export default {
	name: 'AtBottom',
	mixins: [onScreenMixin],
	props: ['action','onCreate'],
	data: () => ({
		created: Date.now()
	}),
	methods: {
		onScreenEnter() {
			// prevent firing if the element was just created onscreen
			const fn = () => {
				if (typeof this.action === 'function') this.action()
				this.$emit('atBottom')
			}
			if (this.onCreate) fn()
			else if (Date.now() - this.created > 500) fn()
		}
	}
}
</script>

<style lang='scss'>
.atBottom {
	min-height: $size-gutter * 2;
}
</style>